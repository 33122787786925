<template>
  <v-card flat class="fullwidth">
    <v-card-text>
      <v-row dense align="stretch" justify="start" class="fill-height">
        <v-col class="d-flex" :cols="mdAndUp ? 6 : 12">
          <v-form v-model="valid" ref="form" class="fullwidth">
            <v-card
              flat
              outlined
              class="rounded-10 fullwidth"
              :style="mdAndUp ? 'height:100vh;' : ''"
            >
              <v-card-title
                class="primary--text fw-800 fs-24 d-flex align-center justify-space-between"
              >
                <span class="">Withdraw</span>
              </v-card-title>
              <v-card-text
                class="d-flex fullwidth align-center justify-center flex-column"
              >
                <div
                  class="fullwidth d-flex flex-column align-start justify-start my-2"
                >
                  <label class="primary--text fw-500"> Destination </label>
                  <v-select
                    outlined
                    dense
                    :rules="[requiredRule()]"
                    v-model="destination"
                    hide-details="auto"
                    class="fullwidth"
                    :items="destinations"
                    item-text="name"
                    item-value="value"
                  > 
                    <template v-slot:append>
                      <HelpToolTip
                        v-if="stripeUser"
                        icon-color="success"
                        icon="mdi-account-check"
                        text="Stripe account connected"
                      ></HelpToolTip>
                      <HelpToolTip
                        v-else
                        icon-color="error"
                        icon="mdi-account-cancel"
                        text="Stripe account not connected"
                      ></HelpToolTip>
                    </template>
                  </v-select>
                  <v-btn
                    v-if="!stripeUser && !fetching"
                    depressed
                    class="text-none my-2 mx-auto px-5"
                    color="primary"
                    @click="
                      $router.push({
                        name: 'connect-stripe',
                        query: { redirect_uri: $route.fullPath }
                      })
                    "
                  >
                    Connect Stripe Account to {{ settings.SiteName }}
                  </v-btn>
                </div>
                <div class="fullwidth my-2">
                  <label class="primary--text fw-500">Amount to withdraw</label>
                  <vuetify-money
                    outlined
                    clearable
                    dense
                    :disabled="balance_available < 2.0 || !stripeUser"
                    :rules="[
                      requiredRule(),
                      minMaxRule(2.0, balance_available)
                    ]"
                    value-when-is-empty="0.00"
                    v-model="amount"
                    hide-details="auto"
                    class="fullwidth"
                    placeholder="Enter amount to withdraw"
                    :options="options_1"
                  ></vuetify-money>
                  <label class="fs-12 red--text" v-if="balance_available < 2.0">
                    Not enough balance, minimum withdrawable amount is $2.00
                  </label>
                </div>
                <div class="fullwidth my-2">
                  <label class="primary--text fw-500">
                    Amount to receive
                  </label>
                  <v-text-field
                    outlined
                    readonly
                    filled
                    dense
                    :key="amount"
                    :value="amount_received"
                    hide-details="auto"
                    class="fullwidth"
                  >
                    <template v-slot:append>
                      <HelpToolTip
                        text="Withdrawal are subject to Stripe Fee of 2.9% + 30¢"
                      ></HelpToolTip>
                    </template>
                  </v-text-field>
                </div>
                <div class="fullwidth my-2">
                  <label class="primary--text fw-500">
                    Enter confirmation code
                  </label>
                  <div
                    class="d-flex fullwidth align-center justify-space-between"
                  >
                    <v-text-field
                      outlined
                      clearable
                      dense
                      :disabled="balance_available < 2.0 || !stripeUser"
                      :rules="requiredRules"
                      hide-details="auto"
                      v-model="code"
                      placeholder="Enter code"
                    ></v-text-field>
                    <v-tooltip left>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          tile
                          v-bind="attrs"
                          v-on="on"
                          :disabled="requesting_code || balance_available < 2.0"
                          @click.stop="sendConfirmationCode"
                          icon
                          :class="
                            requesting_code || balance_available < 2.0
                              ? 'secondary'
                              : 'primary'
                          "
                          :loading="requesting_code"
                          class="ml-1 rounded-5"
                          color="white"
                        >
                          <v-icon>mdi-email-send-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Send confirmation code to email</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-btn
                  @click="sendRequest"
                  :disabled="submitting || !valid || !stripeUser"
                  depressed
                  :loading="submitting"
                  class="text-none my-3 mx-auto"
                  color="primary"
                >
                  Send Withdrawal Request
                </v-btn>
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>
        <v-col class="d-flex" :cols="mdAndUp ? 6 : 12">
          <VueTable
            title="Withdrawal History"
            :show-select="false"
            :loading="loading_items"
            :headers="headers"
            :items="items"
            empty-text="No request yet"
            @load-more="loadMoreItems"
            :no-more-data="!has_more_items"
            :style="mdAndUp ? 'height:100vh;' : ''"
            class="fullwidth"
          >
            <template slot="header-toolbar">
              <v-btn
                class="text-none"
                color="primary"
                depressed
                @click="
                  $router.push({
                    name: 'wallet-history',
                    query: { redirect_uri: $route.fullPath }
                  })
                "
              >
                Wallet History
              </v-btn>
            </template>
            <template v-slot:row-slot="{ item }">
              <td>{{ item.created_at | format('lll') }}</td>
              <td>{{ item.status | ucwords }}</td>
              <td>{{ item.amount | money }}</td>
              <td v-if="item.status === 'pending'" class="text-center">
                <v-btn
                  small
                  @click="cancel_dialog = true"
                  depressed
                  :disabled="cancelling"
                  :loading="cancelling"
                  color="primary"
                  class="mx-auto text-none"
                >
                  <v-icon left color="white">mdi-cancel</v-icon> Cancel
                </v-btn>
              </td>
              <td v-else class="text-center">
                <v-btn
                  small
                  depressed
                  disabled
                  color="primary"
                  class="mx-auto text-none"
                >
                  <v-icon left color="white">mdi-cancel</v-icon> Cancel
                </v-btn>
              </td>
            </template>
          </VueTable>
        </v-col>
      </v-row>
    </v-card-text>

    <DeleteDialog
      v-model="cancel_dialog"
      @delete="cancelRequest"
      delete-button-text="Yes, Cancel Request"
      text-content="Are you sure you want to cancel this withdrawal request?"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import VueTable from '@/common/VueTable/VueTable.vue'
import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'
import { fetch_services } from '@/services/fetch/fetch_services'
import DeleteDialog from '@/common/DeleteDialog.vue'
export default {
  components: { VueTable, DeleteDialog },
  computed: {
    ...mapGetters(['user'])
  },
  mixins: [fetch_services],
  data: () => ({
    stripeUser: null,
    valid: false,
    amount: 0.0,
    code: null,
    destination: 'stripe',
    destinations: [{ name: 'Stripe', value: 'stripe' ,disabled: false }, { name: 'PayPal (Coming soon)', value: 'paypal', disabled: true }],
    requesting_code: false,
    submitting: false,
    cancelling: false,
    cancel_dialog: false,
    fetching: false,
    options_1: {
      locale: 'en-US',
      prefix: '$',
      suffix: '',
      length: 11,
      precision: 2
    },
    headers: [
      { text: 'Date', align: 'left', sortable: false },
      { text: 'Status', align: 'left', sortable: false },
      { text: 'Total Amount', align: 'left', sortable: false },
      { text: 'Action', value: 'actions', align: 'center', sortable: false }
    ]
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('wallet', ['balance_available']),
    amount_received() {
      if (typeof parseFloat(this.amount) !== 'number') return `$ 0.00`
      let amount_deduct = parseFloat(this.amount) * (2.9 / 100) + 0.3
      if (parseFloat(this.amount) < amount_deduct) return `$ 0.00`
      return `$ ${(parseFloat(this.amount) - amount_deduct).toFixed(2)}`
    }
  },
  created() {
    this.loadItems(
      `api/wallet/history/transactions?from=withdrawal`,
      true,
      null,
      null
    )
    this.getStripeInfo()
  },
  methods: {
    clearInputs() {
      this.amount = 0.0
      this.code = null
      this.destination = 'stripe'
    },
    getStripeInfo() {
      this.fetching = true
      request
        .get(`api/stripe/account`)
        .then(({ data }) => {
          this.stripeUser = data
        })
        .finally(() => (this.fetching = false))
    },
    sendConfirmationCode() {
      this.requesting_code = true
      request
        .post(`api/wallet/code-request`, { type: 'withdrawal' })
        .then(({ data }) => {
          this.appSnackbar(
            `Code has been sent to your email ${this.user.email}`,
            'success'
          )
        })
        .finally(() => (this.requesting_code = false))
    },
    sendRequest() {
      this.submitting = true
      request
        .post(`api/wallet/withdrawal-request`, {
          code: this.code,
          amount: this.amount,
          destination: this.destination
        })
        .then(({ data }) => {
          this.items.unshift(data)
          this.appSnackbar(
            `Request has been submitted. Please wait for system approval`,
            'success'
          )
          this.clearInputs()
          this.resetValidation()
        })
        .finally(() => (this.submitting = false))
    },
    cancelRequest() {
      this.cancelling = true
      request
        .put(`api/wallet/cancel-withdrawal-request`, {})
        .then(({ data }) => {
          this.items.splice(0, 1, data)
          this.appSnackbar(`Request has been cancelled.`, 'success')
          this.cancel_dialog = false
        })
        .finally(() => (this.cancelling = false))
    }
  }
}
</script>

<style></style>
